import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/react-css",
  "date": "2015-08-17",
  "title": "REACT & CSS",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "In a scaling project, the way CSS spans out can bring us various problems. Let’s look at these issues, and discuss them in detail."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`We are discussing the following topics:`}</p>
    <ul>
      <li parentName="ul">{`Globals`}</li>
      <li parentName="ul">{`Minification`}</li>
      <li parentName="ul">{`Dependencies`}</li>
      <li parentName="ul">{`Minification`}</li>
      <li parentName="ul">{`Sharing Constants`}</li>
      <li parentName="ul">{`Isolation`}</li>
      <li parentName="ul">{`Non-deterministic Resolution`}</li>
    </ul>
    <h2>{`Globals`}</h2>
    <p>{`Let's start by building a button. In our CSS file, let's create two classes for our buttons.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.btn {
    background: #0000FF;
    border: 1px solid #000;
    border-radius: 2px;
}

.btn-depressed {
    background: #00FFFF;
    color: #0000FF;
}
`}</code></pre>
    <p>{`By adding `}<inlineCode parentName="p">{`.btn`}</inlineCode>{` and `}<inlineCode parentName="p">{`btn-depressed`}</inlineCode>{` to our CSS file,  we have introduced glbal vaiables!`}</p>
    <p>{`CSS is lacking in this regard, that the best practice is to use `}<inlineCode parentName="p">{`global variables`}</inlineCode>{`, when in languages like JavaScript, the best practice is to strictly avoid globals.`}</p>
    <h3>{`CSS Extension`}</h3>
    <p>{`At `}<inlineCode parentName="p">{`Facebook`}</inlineCode>{`, they extended CSS by introducing a new syntax: `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
.btn/container {
    background: #0000FF;
    border: 1px solid #000;
    border-radius: 2px;
}
`}</code></pre>
    <p>{`By adding `}<inlineCode parentName="p">{`/`}</inlineCode>{`, it is now going to be a `}<inlineCode parentName="p">{`local`}</inlineCode>{` variable`}</p>
    <p>{`That means the `}<inlineCode parentName="p">{`.btn/container`}</inlineCode>{` can only be used in the file called `}<inlineCode parentName="p">{`btn.css`}</inlineCode>{`.`}</p>
    <Message type="info" title="Global Variables" content="If you want a global variable, you can append `/public` in front of the class name.<br><br>
  ```
  .btn/container/public {
  	background: #0000FF;
  	border: 1px solid #000;
  	border-radius: 2px;
  }
  ```" mdxType="Message" />
    <p>{`The call-site will be changed by a new function called `}<inlineCode parentName="p">{`cx`}</inlineCode>{` (class extension).`}</p>
    <h2>{`Dependencies`}</h2>
    <p>{`At this point having split our CSS into many files, we will have to manage the dependencies between those files.`}</p>
    <p>{`We can use `}<inlineCode parentName="p">{`cx`}</inlineCode>{` to automatically inject `}<inlineCode parentName="p">{`requireCSS`}</inlineCode>{` call.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
reqireCSS('btn');

<div className={cx('btn/container')}>
`}</code></pre>
    <h2>{`Dead Code Elimination`}</h2>
    <p>{`Since `}<inlineCode parentName="p">{`cx`}</inlineCode>{` is the only way to generate the name, we can also solve the dead code issue.
In your CSS, if you have a rule `}<inlineCode parentName="p">{`.btn/container`}</inlineCode>{`, then search for `}<inlineCode parentName="p">{`btn/container`}</inlineCode>{` in your codebase and you'll find the call-sites. If there is none left, you can kill it!`}</p>
    <h2>{`Minification`}</h2>
    <p>{`We can minify all the class names and send both JS and CSS a bit faster to users.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
._f8z {
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
<div className={'_f8z'}>
`}</code></pre>
    <h2>{`Sharing Constants`}</h2>
    <p>{`Sharing constants between CSS and JavaScript is not ideal. But unfortunately, there are many use cases where you have to do this. You could use comments in both CSS and JS files to keep things in sync. But this approach doesn't scale very well.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
.btn/container {
    margin: 5px;
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
var buttonMargin = 5;
`}</code></pre>
    <h3>{`CSS Variables`}</h3>
    <p>{`To fix this, Facebook borrowed the `}<inlineCode parentName="p">{`var`}</inlineCode>{` syntax specification of CSS, and exposed a `}<inlineCode parentName="p">{`cssVar`}</inlineCode>{` function to JS.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
.btn/container {
    margin: var(button-margin);
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
var buttonMargin = cssVar('button-margin');
`}</code></pre>
    <p>{`To generate those, you have to use your backend.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* CSSVar.php */
$CSSVar = array(
    'button-margin' => 5
);
`}</code></pre>
    <h2>{`Non-deterministic Resolution`}</h2>
    <p>{`What if we need to overrule a style? in CSS `}<inlineCode parentName="p">{`specificity`}</inlineCode>{` plays a big role in this. But if we have our CSS in different files, this becomes a nightmare when the files are bundled and they are loading asynchronously.`}</p>
    <p>{`The most popular way to work around this issue is to increase the specificity of the rule that conflicts but this is super brittle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* overlay-btn.css */
.overlay-btn/container {
    background-color: #000;
    color: '#FFF';
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
.btn/container {
    background-color: #FFF;
    color: '#000';
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
<div className={cx(
    'btn/container/public',
    'overlay-btn/container'
)}
`}</code></pre>
    <h2>{`Isolation`}</h2>
    <p>{`Designers have the ability to modify the style of the internals via selectors. The override looks like regular CSS, so it’s often not being caught by code review. It’s also nearly impossible to write lint rules against it. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* btn.css */
.btn/container {
    /* override everything! */
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* btn.js */
<div className={cx('btn/container')}
`}</code></pre>
    <p>{`Non-deterministic Resolution and isolation is still not resolved yet.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      